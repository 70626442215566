<template>
    <Modal title="编辑数据字典" :width="640" :mask-closable="false" v-model="editFormVisible" :loading="editLoading" ref="editModal"
           @on-ok="editSubmit('editForm')" @on-cancel="editFormVisible=false">
        <Form :model="editForm" ref="editForm" :rules="editFormRules" label-colon :label-width="75">
            <FormItem label="名称" prop="name">
                <Input :disabled="editForm.isReadonly === '1'" show-word-limit :maxlength="50" v-model="editForm.name"/>
            </FormItem>
            <FormItem v-if="editForm.parentId === null" label="编码" prop="code">
                <Input disabled v-model="editForm.code"/>
            </FormItem>
            <FormItem v-if="editForm.parentId !== null" label="父ID" prop="parentId">
                <Input v-if="editForm.isReadonly === '1'" disabled v-model="editForm.parentName"/>
                <tree-select v-else noOptionsText="暂无数据" :options="parentArrays" :show-count="true" placeholder="请选择"
                             v-model="editForm.parentId" :defaultExpandLevel="Infinity" :isDefaultExpanded="true"/>
            </FormItem>
            <FormItem label="序号" prop="serialNumber">
                <Input placeholder="请输入序号" v-model.trim="editForm.serialNumber"/>
            </FormItem>
            <FormItem label="备注" prop="remark">
                <Input type="textarea" placeholder="请输入" show-word-limit :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" v-model="editForm.remark" />
            </FormItem>
        </Form>
    </Modal>
</template>

<script>
import {reqEditDictionary, reqGetDictionaryOne} from '../../../api/system-api';
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "editDictionary",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        parentArrays: {
            type: Array
        },
        id: {
            type: Number,
        }
    },
    components: {
        TreeSelect
    },
    data() {
        return {
            editFormVisible: this.value,//编辑界面是否显示
            editLoading: false,
            editFormRules: {
                name: [
                    {required: true, message: '请输入名称', trigger: 'blur'},
                    {min: 1, max: 50, message: '请输入小于50个字符的名称', trigger: 'blur'},
                ],
                code: [
                    {required: true, message: '请输入编码', trigger: 'blur'},
                    {min: 1, max: 50, message: '请输入编码', trigger: 'blur'}
                ],
                parentId: [
                    {required: true, type: 'number', message: '请选择父级', trigger: 'changer'},
                ],
                serialNumber: [
                    {pattern: /^[1-9]\d*$/, message: '该字段为整数', trigger: 'blur'},
                    {pattern: /^(?!(\s+$))/, message: '不能以空格开头', trigger: 'blur'},
                    {min: 1, max: 4, message: '请输入小于9999的数字', trigger: 'blur'}
                ],
                remark: [
                    {message: '请输入备注', trigger: 'blur'},
                    {min: 1, max: 500, message: '请输入小于500个字符的描述', trigger: 'blur'}
                ],
            },
            editForm: {
                id: "",
                name: '',
                code: '',
                remark: '',
                serialNumber: '',
                parentId: null,
                parentName: '',
                isReadonly: ''
            },
        }
    },
    methods: {
        //编辑
        editSubmit: function () {
            this.$refs.editForm.validate((valid) => {
                let that = this;
                if (valid) {
                    that.editLoading = true;
                    that.editForm.serialNumber = that.editForm.serialNumber ? that.editForm.serialNumber : null;
                    let para = Object.assign({}, that.editForm);
                    reqEditDictionary(para).then(res => {
                        that.editLoading = false;
                        if (res.data.code === 1) {
                            that.$Message.success(res.data.msg);
                            that.closeModal(false);
                            that.$emit('goReset');
                        } else {
                            that.closeModal(true);
                            that.$Message.warning(res.data.msg);
                        }
                    }).catch(() => {
                        that.editLoading = false;
                        that.$Message.error("编辑失败");
                    });
                }
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        },
    },

    watch: {
        value(val) {
            this.editFormVisible = val;
        },
        editFormVisible(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.editFormVisible = val;
                reqGetDictionaryOne({id: this.id}).then(res => {
                    this.editForm = res.data.data;
                });
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }

}
</script>
