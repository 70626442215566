<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>数据字典</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.name" placeholder="请输入名称" clearable/>
                </FormItem>
                <FormItem>
                    <Input v-model="filter.code" placeholder="请输入编码" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" v-on:click="getDictionaryPageList(0)">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="showAddDialog" icon="md-add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe row-key="id" :load-data="getChildren" :columns="columns" :data="dictionaries"
                       :loading="listLoading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a type="text" @click="showEditDialog(row,index)" ghost>编辑</a>
                        <Divider v-if="row.isReadonly === '0'" type="vertical"/>
                        <a type="error" @click="delDictionary(row.index,row)"
                           v-if="row.isReadonly === '0'" ghost>删除</a>
                    </template>
                </Table>
                <addDictionary v-model="addFormVisible" :parentArrays="parentArray"
                               v-on:getDictionaryPageList="getDictionaryPageList"></addDictionary>
                <editDictionary v-model="editFormVisible" :parentArrays="parentArray" :id="id"
                                v-on:getDictionaryPageList="getDictionaryPageList"></editDictionary>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ current }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :total="total" :page-size="pageSize" :current="current" @on-change="changePage"
                              @on-page-size-change="changPageSize" :page-size-opts="[15,20,50,100]" show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>
<script>
import {reqGetDictionaryPageList, reqDeleteDictionary, reqGetDictionaryChildren, reqGetDictionaryParentList} from '../../../api/system-api';
import addDictionary from '../dictionary/add'
import editDictionary from '../dictionary/edit';

export default {
    components: {
        addDictionary,
        editDictionary
    },
    data() {
        return {
            filter: {
                name: '',
                code: '',
            },
            dictionaries: [],
            parentArray: [],
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    key: 'index',
                    width: 80
                },
                {
                    title: '名称',
                    key: 'name',
                    tree: true,
                    width: 270
                },
                {
                    title: '编码',
                    key: 'code',
                    width: 230
                },
                {
                    title: '序号',
                    key: 'serialNumber',
                    width: 120
                },
                {
                    title: '备注',
                    key: 'remark'
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120
                }
            ],
            total: 0,
            pageNum: 1,
            pageSize: 20,
            current: 0,
            pages: 1,
            listLoading: false,
            id: null,
            addForm: {
                id: "",
                name: '',
                code: '',
                remark: '',
                serialNumber: null,
                parentId: ""
            },
            editFormVisible: false,//编辑界面是否显示
            addFormVisible: false
        }
    },
    methods: {
        changePage(val) {
            this.pageNum = val;
            this.getDictionaryPageList();
        },
        changPageSize(val) {
            this.pageSize = val;
            this.getDictionaryPageList();
        },
        getDictionaryPageList(status) {
            let that = this;
            that.listLoading = true;
            that.pageNum = status === '0' ? 1 : that.pageNum;
            let para = {
                code: that.filter.code,
                name: that.filter.name,
                pageNum: that.pageNum,
                pageSize: that.pageSize
            };
            reqGetDictionaryPageList(para).then((res) => {
                that.listLoading = false;
                if (res.data.code === 1) {
                    let records = res.data.data.records;
                    records.forEach(item => {
                        if (item.numberOfChild > 0) {
                            item.children = [];
                            item._loading = false;
                        }
                    });
                    that.total = res.data.data.total;
                    that.pages = res.data.data.pages;
                    that.current = res.data.data.current;
                    that.dictionaries = records;
                }
            }).catch(() => {
                that.listLoading = false;
            })
        },
        getChildren(item, callback) {
            let that = this;
            let records = [];
            reqGetDictionaryChildren({parentId: item.id}).then((res) => {
                records = res.data.data;
                records.forEach(item => {
                    if (item.numberOfChild > 0) {
                        item.children = [];
                        item._loading = false;
                    }
                });
                callback(records);
            }).catch((err) => {
                callback(records);
            })
        },
        getParentDictionaries() {
            reqGetDictionaryParentList({}).then((res) => {
                this.parentArray = res.data.data;
            });
        },
        //删除
        delDictionary: function (index, row) {
            this.$Modal.confirm({
                title: ' ',
                content: '确定删除？',
                width: '240',
                onOk: () => {
                    let para = {id: row.id};
                    reqDeleteDictionary(para).then(res => {
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            this.getDictionaryPageList();
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.$Message.error("删除失败");
                    });
                }
            })
        },
        //显示添加界面
        showAddDialog: function () {
            this.addFormVisible = true;
            this.getParentDictionaries();
        },
        //显示编辑界面
        showEditDialog(row, index) {
            this.getParentDictionaries();
            this.id = row.id;
            this.editFormVisible = true;
        },
    },
    mounted() {
        this.getDictionaryPageList();
    }
}

</script>
